<template>
  <div>
    <b-row>
      <b-col col="6" class="center-icon">
        <b-avatar size="120" variant="light-primary" class="center-icon">
          <b-row>
            <b-col><feather-icon icon="UserIcon" class="mr-50" size='50%' />
            </b-col>
          </b-row>
        </b-avatar>
        <b-row class="mt-1">
          <b-col>
            <h6 class="text-center">Pengaduan</h6>
          </b-col>
        </b-row>
      </b-col>
      <b-col col="6" class="center-icon">


        <b-avatar size="120" variant="light-primary" rounded="" class="center-icon">
          <b-img fluid :src="require('@/assets/images/pages/phjd.png')" alt="Login V2"
            style="ustify-self: bottom;width: 90%" />
        </b-avatar>
        <b-row class="mt-1">
          <b-col>
            <h6 class="text-center">PHJD</h6>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from '@/config/Axios'
import Base from '@/config/Mixins_base'
import {
  BTabs,
  BTab, BAvatar, BCol, BRow, BFormGroup, BInputGroup, BInputGroupPrepend, BFormInput, BCard, BCardText, VBTooltip, BImg
} from 'bootstrap-vue'
export default {
  components: {
    BTabs,
    BTab, BAvatar, BCol, BRow, BFormGroup, BInputGroup, BInputGroupPrepend, BFormInput, BCard, BCardText, VBTooltip, BImg
  },
  data() {
    return {

    }
  },
  mixins: [Base],
  mounted() {


  },
  methods: {
    async xxx() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  },
  props: {

  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },

}
</script>

<style>
.center-icon {
  text-align: center;
}
</style>